define("discourse/plugins/discourse-category-lockdown/discourse/connectors/category-custom-security/lockdown-settings", ["exports", "@glimmer/component", "discourse/models/group", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _group, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LockdownSettings extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "selectedGroups", [_tracking.tracked], function () {
      return null;
    }))();
    #selectedGroups = (() => (dt7948.i(this, "selectedGroups"), void 0))();
    constructor() {
      super(...arguments);
      this.selectedGroups = (this.args.outletArgs.category.custom_fields.lockdown_allowed_groups || "").split(",").filter(Boolean);
    }
    get availableGroups() {
      return (this.site.groups || []).map(g => {
        // prevents group "everyone" to be listed
        return g.id === 0 ? null : g.name;
      }).filter(Boolean);
    }
    static #_3 = (() => dt7948.n(this.prototype, "availableGroups", [(0, _object.computed)("site.groups.[]")]))();
    onChangeGroups(values) {
      this.selectedGroups = values;
      this.args.outletArgs.category.custom_fields.lockdown_allowed_groups = values.join(",");
    }
    static #_4 = (() => dt7948.n(this.prototype, "onChangeGroups", [_object.action]))();
  }
  _exports.default = LockdownSettings;
  ;
});